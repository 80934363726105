import {useTranslation} from "react-i18next";
import {getPath} from '../../helpers/path';

export const ProductTitle = () => {

    //Translation init
    const { t } = useTranslation();

    //Get the path (= product)
    const path = getPath();

    return (
        <div className="mt-6">
            <h1 className="flex justify-between mx-4 whitespace-nowrap text-2xl">{ t(`${path}.title`) }</h1>
        </div>
    )
}
