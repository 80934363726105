import axios from 'axios';

const headers = {
    'Accept-Language': 'fr-FR',
    'Accept': 'application/json; version=3',
    'ContentType': 'application/json',
    'Device-OS-Name': 'web',
    'User-Locale': 'fr'
}

const httpClient = axios.create({ headers: headers });

/**
 * Get prices in currency from API
 */
export const getPrices = (currency: string): Promise<any> => {
    return httpClient.get<{ prices: IPrices }>(`/api/prices?currency=${currency}`)
        .then(response => response.data?.prices)
        .catch(err => err);
}

/**
 * Price interface from API
 */
export interface IPrices {
    currency: string,
    credit: {
        original_price: number | null,
        price: number
    },
    shipping_cost: {
        original_price: number | null,
        price: number
    }
}
