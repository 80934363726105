import {allProductsOrdered} from "./products";
import {IPrices} from '../services/prices.service';

interface CarteBlanchePlan { quarterly: number, annual: number }
interface FamilySpacePlan { monthly: number, annual: number }
export interface Prices { priceCents: number, originalPriceCents: number, shippingPriceCents: number, currency?: string | null }

// KEY VALUE FOR localStorage
const CURRENCY = 'currency';
const PRICE_CENTS = 'priceCents';
const ORIGINAL_PRICE_CENTS = 'originalPriceCents';
const SHIPPING_PRICE_CENTS = 'shippingPriceCents';

/**
 * Retrieve prices from localStorage
 */
export const getPricesFromLocalStorage = (): Prices | null => {
    // retrieve prices from localStorage
    const currency = localStorage.getItem(CURRENCY);
    const priceCents = localStorage.getItem(PRICE_CENTS);
    const originalPriceCents = localStorage.getItem(ORIGINAL_PRICE_CENTS);
    const shippingPriceCents = localStorage.getItem(SHIPPING_PRICE_CENTS);

    if(!priceCents || !originalPriceCents || !shippingPriceCents){
        return null;
    }

    return {
        currency: currency,
        priceCents: parseFloat(priceCents),
        originalPriceCents: parseFloat(originalPriceCents),
        shippingPriceCents: parseFloat(shippingPriceCents)
    }
}

/**
 * Set into local storage the different prices
 * @param prices
 */
export const setPricesIntoLocalStorage = (prices: IPrices) => {
    if(prices.currency){
        localStorage.setItem(CURRENCY, prices.currency);
    }
    if(prices.credit.price){
        localStorage.setItem(PRICE_CENTS, prices.credit.price.toString());
    }
    if(prices.credit.original_price){
        localStorage.setItem(ORIGINAL_PRICE_CENTS, prices.credit.original_price.toString());
    }
    if(prices.shipping_cost.price){
        localStorage.setItem(SHIPPING_PRICE_CENTS, prices.shipping_cost.price.toString());
    }
}

/**
 * Return the price of one credit for a subscribed user
 */
const getSubscribedUserCreditPrice = (): number => {
    const prices = getPricesFromLocalStorage();
    return prices ? prices.priceCents : 0;
}

/**
 * Get original price (from 1 credit plan)
 */
const getOriginalCreditPrice = (): number => {
    const prices = getPricesFromLocalStorage();
    return prices ? prices.originalPriceCents : 0;
}

/**
 * Shipping cost of a product by currency
 */
export const getShippingCost = () => {
    const prices = getPricesFromLocalStorage();
    return prices ? prices.shippingPriceCents : 0;
}

/**
 * Get credit number for a product
 * @param product the user's product wants to buy
 */
export const getCreditsOf = (product: string): number | null => {
    switch (product){
        case 'postcard':
            return 1;
        case 'card-video':
        case 'greeting':
        case 'magnetic':
        case 'square-magnetic':
            return 2;
        case 'card-video-magnetic':
            return 3;
        case 'gazette':
        case 'family-space':
        case 'magazine':
            return 5;
        case 'card-set':
        case 'photobook':
        case 'card-set-and-photobook':
            return 10;
        default:
            return 1;
    }
}

/**
 * Get the price of a product in its currency
 * @param product the name of the product
 */
export const getPriceOf = (product: string): Prices | null => {

    const nbCredits = getCreditsOf(product);
    if(!nbCredits){
        return null;
    }

    const shippingCost = getShippingCost();
    const price = getSubscribedUserCreditPrice() * (nbCredits-1);
    const originalPrice = getOriginalCreditPrice() * nbCredits;

    return {
        priceCents: price * 100,
        originalPriceCents: originalPrice * 100,
        shippingPriceCents: shippingCost * 100
    }
}

/**
 * Get the carte blanche sub prices (annual & trimestrial)
 * @param currency the currency in which you want the plan
 */
export const getCarteBlanchePrices = (currency: string): CarteBlanchePlan => {
    switch (currency){
        case 'AUD':
            return { annual: 599, quarterly: 899  }
        case 'CAD':
            return { annual: 559, quarterly: 839  }
        case 'CHF':
            return { annual: 419, quarterly: 619  }
        case 'GBP':
            return { annual: 339, quarterly: 509  }
        case 'USD':
            return { annual: 449, quarterly: 669  }
        case 'EUR':
        default:
            return { annual: 399, quarterly: 599  }
    }
}

/**
 * Get the family space sub prices (annual & monthly)
 * @param currency the currency in which you want the plan
 */
export const getFamilySpacePrices = (currency: string): FamilySpacePlan => {
    switch (currency){
        case 'AUD':
            return { annual: 17190, monthly: 1719 }
        case 'CAD':
            return { annual: 14890, monthly: 1489 }
        case 'CHF':
            return { annual: 11190, monthly: 1119 }
        case 'GBP':
            return { annual: 9590, monthly: 959 }
        case 'USD':
            return { annual: 10790, monthly: 1079 }
        case 'EUR':
        default:
            return { annual: 10990, monthly: 1099 }
    }
}

/**
 * Return the minimum price difference of a product between a subscriber and a non-subscriber in percent
 */
export const calculateMinDiffPercent = (): number => {
    let percentTab: number[] = [];
    allProductsOrdered.forEach(product => {
        const diff = getPercentageDifference(product);
        if(diff) percentTab.push(diff);
    });
    return Math.min(...percentTab);
}

/**
 * Return the maximum price difference of a product between a subscriber and a non-subscriber in percent (postcard excluded)
 */
export const calculateMaxDiffPercentPostcardExcluded = (): number => {
    let percentTab: number[] = [];
    allProductsOrdered.filter(products => products !== 'postcard').forEach(product => {
        const diff = getPercentageDifference(product);
        if(diff) percentTab.push(diff);
    });
    return Math.max(...percentTab);
}

/**
 * Return the percent difference between the original price and the subscribed price
 * @param product the product to get the difference percent
 */
export const getPercentageDifference = (product: string): number | null => {
    let price = getPriceOf(product);
    if(!price) return null;
    let diff = ((price.originalPriceCents - (price.priceCents + price.shippingPriceCents))/price.originalPriceCents);
    return Math.round(diff*100);
}
