const currencyByBrowserLanguage = (browserLanguage: string) => {
    switch (browserLanguage) {
        case 'en-US':
            return 'USD'
        case 'en-GB':
            return 'GBP'
        case 'en-AU':
            return 'AUD'
        case 'en-ca':
            return 'CAD'
        case 'fr-ca':
            return 'CAD'
        case 'fr-ch':
            return 'CHF'
        case 'de-ch':
            return 'CHF'
        case 'it-ch':
            return 'CHF'
        default:
            return 'EUR'
    }
}

const priceFormatByCurrency = (currency: string) => {
    switch (currency) {
        case 'AUD':
            return 'en-AU'
        case 'CAD':
            return 'en-CA'
        case 'CHF':
            return 'ch'
        case 'EUR':
            return 'fr-FR'
        case 'GBP':
            return 'en-GB'
        case 'USD':
            return 'en-US'
        default:
            return 'fr-FR'
    }
}

export const formatPrice = (priceCents: number, currency: string, minimumFractionDigits = 0) => {
    const formatter = new Intl.NumberFormat(priceFormatByCurrency(currency), {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: 2
    })

    return formatter.format(priceCents / 100)
}

export const currencyByUrlParamOrBrowserLanguage = () => {
    // currency from url param
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlParam = urlParams.get('currency');

    // currency from navigator
    const browserLanguage = navigator.language

    const validCurrencies = ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'USD'];

    return (urlParam !== null && validCurrencies.indexOf(urlParam.toUpperCase()) > -1)
        ? urlParam.toUpperCase()
        : currencyByBrowserLanguage(browserLanguage);
}
