export const NotFound = () => {
    return (
        <div className="flex flex-col text-center items-center px-8 py-4">
            <img width="350px" src={"./assets/images/not-found/img.png"} alt="Not found page logo" />

            <p className="mt-8 text-3xl font-bold text-black">Page not found</p>

            <p className="mt-8 text-black">
                This page does not exist. Check if the addres is correct.<br />
                If the problem persists, contact us at <a className="email" href="mailto:hello@fizzer.coe">hello@fizzer.com</a>
            </p>
        </div>
    )
}