interface DescriptionProps {
    description: string[],
    descriptionBullet?: string[]
}

export const Description = (props: DescriptionProps) => {

    //The description sentences
    const description = props?.description?.map((s: string, i: number) => {
        return <p key={i}>{s}</p>
    });

    //The description with bullet points
    const descriptionBullet = props?.descriptionBullet?.map((s: string, i: number) => {
        return <li key={i}>{s}</li>
    });

    return(
        <div className="mt-6 mx-4">
            {description ? description : null}
            <ul className="mt-6 ml-4 list-disc">{descriptionBullet ? descriptionBullet : null}</ul>
        </div>

    )
}
