import '../../assets/styles/table.scss';
import {useTranslation} from "react-i18next";
import {currencyByUrlParamOrBrowserLanguage, formatPrice} from "../../helpers/currencies";
import {getCreditsOf, getPercentageDifference, getPriceOf} from "../../helpers/prices";
import {allProductsOrdered} from "../../helpers/products";
import {capitalizeFirstLetter} from "../../helpers/strings";

export const ProductPricesTab = () => {

    //Init translation
    const { t } = useTranslation();

    // currency
    const currency = currencyByUrlParamOrBrowserLanguage();

    /**
     * Return the correct price value of the product in its currency
     * @param product the product to get the price
     */
    const getOriginalPrice = (product: string): string | null => {
        let price = getPriceOf(product);
        if(!price) return null;
        return formatPrice(price.originalPriceCents, currency, 2);
    }

    /**
     * Return the correct price value of the product in its currency
     * @param product the product to get the price
     */
    const getSubscribedPrice = (product: string): string | null => {
        let price = getPriceOf(product);
        if(!price) return null;
        return formatPrice((price.priceCents + price.shippingPriceCents), currency, 2);
    }

    return (
        <div className="relative">
            <table className="mb-3">
                <thead>
                <tr>
                    <td>{ t('common.carte-blanche.tab-prices.our-products') }</td>
                    <td>{ t('common.carte-blanche.tab-prices.sub-prices') }</td>
                    <td>{ t('common.carte-blanche.tab-prices.non-sub-prices') }</td>
                    <td>{ t('common.carte-blanche.tab-prices.discount') }</td>
                </tr>
                </thead>
                <tbody>
                {
                    allProductsOrdered.map((product: string, i: number) => {
                        // only display product if got credits
                        const displayProduct: boolean = getCreditsOf(product) !== null;
                        if(displayProduct){
                            return (
                                <tr key={i}>
                                    <td>{ capitalizeFirstLetter(t(`${product}.title`)) }</td>
                                    <td>{ getSubscribedPrice(product) }</td>
                                    <td>{ getOriginalPrice(product) }</td>
                                    <td>{ `${getPercentageDifference(product)}%` }</td>
                                </tr>
                            );
                        }
                    })
                }
                </tbody>
            </table>
            <img className="absolute -bottom-4 -right-3 w-1/6" src={'./assets/images/faq-carte-blanche/postcard_carto_hello_mascotte.png'} alt="hello mascotte" />
        </div>
    )
}
