import cardSet from './card-set.json';
import cardSetAndPhotobook from './card-set-and-photobook.json';
import cardVideo from './card-video.json';
import cardVideoMagnetic from './card-video-magnetic.json';
import familySpace from './family-space.json'
import magazine from './magazine.json';
import greeting from './greeting.json';
import magnetic from './magnetic.json';
import photoBook from './photobook.json';
import postcard from './postcard.json';
import squareMagnetic from './square-magnetic.json';
import common from './common.json';
import gift from './gift.json';

const FR = {
    "card-set": cardSet,
    "card-set-and-photobook": cardSetAndPhotobook,
    "card-video": cardVideo,
    "card-video-magnetic": cardVideoMagnetic,
    "family-space": familySpace,
    "magazine": magazine,
    "gazette": magazine,
    "gift": gift,
    "greeting": greeting,
    "magnetic": magnetic,
    "photobook": photoBook,
    "postcard": postcard,
    "square-magnetic": squareMagnetic,
    "common": common
};

export default FR;
