import {HeaderImage} from "../../components/products/header-image";
import {Description} from "../../components/products/description";
import {useTranslation} from "react-i18next";
import {ProductTitle} from "../../components/products/product-title";

export const Gift = () => {

    //Translation init
    const { t } = useTranslation();

    return(
        <div>
            <HeaderImage imgCount={1} />
            <ProductTitle/>
            <Description description={t('gift.description', { returnObjects: true })} />

        </div>
    )
}
