import {useTranslation} from "react-i18next";
import {FaqPanel, IFaq} from "../../components/subscription-faq/faq-panel";

export const FamilySpaceFaq = () => {

    // force family space panel
    const FAMILY_SPACE = 99;

    //Init translation
    const { t } = useTranslation();
    const faqs: IFaq[] = t('common.family-space.faq', { returnObjects: true });

    return(
        <>
            <p className="font-bold text-center mt-10">{ t('common.family-space.faq-title') }</p>
            { faqs.map((faq: IFaq, i: number) => <FaqPanel id={FAMILY_SPACE} fsPrice={i===6} key={i} faq={faq} />) }
        </>
    )
}
