import {HeaderImage} from "../../components/products/header-image";
import {ProductTitle} from "../../components/products/product-title";
import {useTranslation} from "react-i18next";
import {Description} from "../../components/products/description";
import {Instructions} from "../../components/products/instructions";
import {Faq} from "../../components/products/faq";

export const CardSet = () => {

    //Translation init
    const { t } = useTranslation();

    return(
        <div>
            <HeaderImage imgCount={1} />
            <ProductTitle />
            <Description description={t('card-set.description', { returnObjects: true })} descriptionBullet={t('card-set.description-bullet', { returnObjects: true })} />
            <Instructions title={t('card-set.instructions.title')} instructions={t('card-set.instructions.blocks', { returnObjects: true })} />
            <Faq />
        </div>
    )
}
