import {PopupContext} from "../contexts/popup-context";
import {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {Trans, useTranslation} from "react-i18next";
import {currencyByUrlParamOrBrowserLanguage, formatPrice} from "../helpers/currencies";
import {getCreditsOf, getPriceOf} from "../helpers/prices";
import {getPath} from '../helpers/path';

export const Popup = () => {

    //Popup context
    const { display, shipping, setDisplay } = useContext(PopupContext);

    //Get path for the product
    const path = getPath();

    //Search params for the currency
    const currency = currencyByUrlParamOrBrowserLanguage();

    //Get price and credits
    const price = getPriceOf(path);
    const credits = getCreditsOf(path);

    //Translation init
    const { t } = useTranslation();

    if(!display){
        document.body.style.overflow = "scroll";
        return null;
    } else {
        document.body.style.overflow = "hidden";
        return (
            <div className="fixed top-0 bottom-0 w-full h-full backdrop-brightness-75 flex justify-center">

                <div className="relative w-4/5 my-auto mx-auto rounded-3xl shadow-xl place-items-center">
                    <div className="flex place-items-center justify-center bg-white p-3 font-bold text-xl rounded-t-3xl">
                        {t('common.carte-blanche.popup.title')}
                        <FontAwesomeIcon onClick={() => setDisplay(false, false)} className="h-6 absolute cursor-pointer text-gray-500 top-3 right-5" icon={faXmark} />
                    </div>
                    <div className="bg-fizzer-50 rounded-b-3xl px-3 py-6 text-center">
                        { price ? <>
                            { !shipping ?
                                <>
                                    <p className="text-secondary font-medium">
                                        <Trans
                                            i18nKey="common.carte-blanche.popup.price"
                                            values={{ credits: credits, price: formatPrice(price.originalPriceCents, currency, 2), count: credits }}
                                        />
                                    </p>
                                    <p className="font-medium">{t('common.carte-blanche.popup.non-subscribers')}</p>
                                </>
                            :
                                <p className="font-medium text-secondary">{`${ formatPrice(price.priceCents, currency, 2) } (+${formatPrice(price.shippingPriceCents, currency, 2)} ${t('common.carte-blanche.for-shipping')})`}</p>
                            } </> : null
                        }
                    </div>
                </div>

            </div>
        )
    }
}
