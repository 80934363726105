import {useTranslation} from "react-i18next";
import {HeaderImage} from "../../components/products/header-image";
import {ProductTitle} from "../../components/products/product-title";
import {Description} from "../../components/products/description";
import {Instructions} from "../../components/products/instructions";
import {Faq} from "../../components/products/faq";

export const SquareMagnetic = () => {

    //Translation init
    const { t } = useTranslation();

    return (
        <div>
            <HeaderImage imgCount={2} />
            <ProductTitle />
            <Description description={t('square-magnetic.description', { returnObjects: true })} descriptionBullet={t('square-magnetic.description-bullet', { returnObjects: true })} />
            <Instructions title={t('square-magnetic.instructions.title')} instructions={t('square-magnetic.instructions.blocks', { returnObjects: true })} />
            <Faq />
        </div>
    )
}
