import {HeaderImage} from "../../components/products/header-image";
import {useTranslation} from "react-i18next";
import {ProductTitle} from "../../components/products/product-title";
import {Description} from "../../components/products/description";
import {Instructions} from "../../components/products/instructions";
import {Faq} from "../../components/products/faq";

export const PhotoBook = () => {
    
    //Translation init
    const { t } = useTranslation();

    return (
        <div>
            <HeaderImage imgCount={2} />
            <ProductTitle />
            <Description description={t('photobook.description', { returnObjects: true })} descriptionBullet={t('photobook.description-bullet', { returnObjects: true })} />
            <Instructions title={t('photobook.instructions.title')} instructions={t('photobook.instructions.blocks', { returnObjects: true })} />
            <Faq />
        </div>
    )
}
