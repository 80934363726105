import {useState} from "react";
import {Trans} from 'react-i18next';

interface PanelProps {
    title: string,
    answer: string
}

export const Panel = (props: PanelProps) =>  {

    //State of the dropdown (open = true / close = false)
    const [toggle, setToggle] = useState(false);//False init

    return (
        <div className="cursor-pointer w-full pb-8 mt-8 border-b" onClick={() => setToggle(!toggle)}>
            <div className="flex justify-between place-items-center">
                <h4 className="ml-6 border-r-amber-800">{props.title}</h4>
                <div className={`mr-6 carret${ toggle ? '-open' : '-close'}`} />
            </div>
            <div className={`panel${ toggle ? '-open' : '-close'}`}>
                <p className={toggle ? "visible px-8 pt-8" : "hidden"}><Trans i18nKey={props.answer} /></p>
            </div>
        </div>
    )
}
