import {useTranslation} from "react-i18next";
import {FaqPanel, IFaq} from "../../components/subscription-faq/faq-panel";

export const ClubFizzerFaq = () => {

    //Init translation
    const { t } = useTranslation();
    const faqs: IFaq[] = t('common.carte-blanche.faq', { returnObjects: true });

    return(
        <>
            <p className="font-bold text-center mt-10">{ t('common.carte-blanche.faq-title') }</p>
            <img className="w-4/12 md:w-3/12 lg:w-2/12 flex mx-auto mt-5 mb-10" src={"./assets/images/faq-carte-blanche/fizzer_club.svg"} alt="carte blanche" />
            { faqs.map((faq: IFaq, i: number) => <FaqPanel id={i} key={i} faq={faq} />) }
        </>
    )
}
