import {useTranslation} from "react-i18next";
import {DetailQuestionAnswer, IQuestionAnswer} from "../../../components/products/detail/detail-question-answer";
import {getCreditsOf} from "../../../helpers/prices";
import {allProductPages} from "../../../helpers/products";
import {NotFound} from "../../not-found";

export const Detail = () => {

    //Init translation
    const { t } = useTranslation();

    //Get product
    const product = document.location.pathname.split('/')[2];

    //Questions/Answer
    const nbCredits = getCreditsOf(product);
    const questionsAnswers : IQuestionAnswer[] = t('common.details', { returnObjects: true, count: nbCredits ? nbCredits : 0 });

    //If the product is not in the product list => not found
    if(!allProductPages.includes(product)) return <NotFound />

    return <div className="flex flex-col place-items-center mt-6 px-8">
        { nbCredits === 1 ? <img alt="1 credit" className="w-2/3" src={"./assets/images/postcard/cost.png"} /> : nbCredits === 2 ? <img alt="2 credits" className="w-1/2" src={"./assets/images/photobook/cost.png"} /> : null }
        { nbCredits ? questionsAnswers?.map((qa: IQuestionAnswer, i: number) => <DetailQuestionAnswer key={i} index={i} qa={qa} product={product} credits={nbCredits} />) : null }
    </div>

}
