import {Panel} from "./panel";
import {useTranslation} from "react-i18next";
import {getPath} from '../../helpers/path';

export const Faq = () => {

    //Translation init
    const { t } = useTranslation();

    //Get the path for the translation
    const path = getPath();

    //Get all the FAQ
    const faq: {title: string, answer: string}[] = t(`${path}.panel`, { returnObjects: true })

    return (
        <div className="mt-16 mb-4">
            { faq.map((question: {title: string, answer: string}, i: number) => {
                return <Panel title={question.title} answer={question.answer} key={i} />
            }) }
        </div>
    )
}
