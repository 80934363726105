export const allProductsOrdered = [
    'postcard',
    'card-video',
    'magnetic',
    'square-magnetic',
    'greeting',
    'card-video-magnetic',
    'magazine',
    'card-set-and-photobook'
];

export const allProductPages = [
    'card-set',
    'card-video',
    'magazine',
    'greeting',
    'magnetic',
    'photobook',
    'postcard',
    'square-magnetic'
];

export const getProductIdFromProduct = (product: string) => {
    switch (product){
        case 'card-set':
            break;
    }
}
