import {useState} from "react";
import {ProductPricesTab} from "./product-prices-tab";
import {currencyByUrlParamOrBrowserLanguage, formatPrice} from "../../helpers/currencies";
import {
    calculateMaxDiffPercentPostcardExcluded,
    calculateMinDiffPercent,
    getCarteBlanchePrices,
    getFamilySpacePrices,
    getShippingCost
} from "../../helpers/prices";
import {Trans, useTranslation} from "react-i18next";

export interface IFaq { question: string, response: string, responses: IResponses[] }
export interface IResponses { title: string, bullets: string[] }

export const FaqPanel = (props: { faq: IFaq, id: number, fsPrice?: boolean }) => {

    //Init translation
    useTranslation();

    //State of the panel
    const [opened, setOpened] = useState(false);

    //Index of the faq panel needs the price table
    const FAQ_PERCENT = 0;
    const FAQ_TAB_INDEX = 1;
    const FAQ_SUB_PLAN_PRICES = 2;
    const FAQ_PERCENT_MIN_MAX = 3;
    const FAQ_WITH_BULLET_POINTS = 99; // for bullets points with sub-responses (case for family space)

    // currency
    const currency = currencyByUrlParamOrBrowserLanguage();

    //Get the shipping costs prices
    const shippingCosts = getShippingCost()*100;
    const carteBlanchePrices = getCarteBlanchePrices(currency);
    const percent = calculateMinDiffPercent()+'%';
    const percentMaxPostcardExcluded = calculateMaxDiffPercentPostcardExcluded()+'%';

    /**
     * Depend on the index of the question/response. We need set some variables
     * @param index the index of the question/response
     */
    const setResponse = (index: number) => {
        switch(index){
            case FAQ_PERCENT:
                return <Trans i18nKey={props.faq.response} values={{ percent: percentMaxPostcardExcluded }} />
            case FAQ_TAB_INDEX:
                return (
                    <>
                        <Trans i18nKey={props.faq.response} values={{ shipping: formatPrice(shippingCosts, currency, 2), percent: percent }} />
                        <ProductPricesTab />
                    </>
                );
            case FAQ_SUB_PLAN_PRICES:
                let trimestrial_m = formatPrice(carteBlanchePrices.quarterly, currency, 2);
                let trimestrial = formatPrice(carteBlanchePrices.quarterly*3, currency, 2);
                let annual_m = formatPrice(carteBlanchePrices.annual, currency, 2);
                let annual = formatPrice(carteBlanchePrices.annual*12, currency, 2);
                return <Trans i18nKey={props.faq.response} values={{ trimestrial_m: trimestrial_m, trimestrial: trimestrial, annual_m: annual_m, annual: annual }} />
            case FAQ_PERCENT_MIN_MAX:
                let maxPercent = calculateMaxDiffPercentPostcardExcluded();
                return <Trans i18nKey={props.faq.response} values={{ min: percent, max: `${maxPercent}%` }} />
            case FAQ_WITH_BULLET_POINTS:
                // get all the family prices
                const familySpacePrices = getFamilySpacePrices(currency);
                const fsAnnual = formatPrice(familySpacePrices.annual, currency, 2);
                const fsMonthly = formatPrice(familySpacePrices.monthly, currency, 2);
                const fsAnnualInMonth = formatPrice((familySpacePrices.annual/12), currency, 2);
                return <>
                    { props.faq?.responses?.map((response, index) => <ul key={index} className={'list-disc'}>
                        <p className={'mt-5'}><Trans i18nKey={response.title} /></p>
                        { response?.bullets?.map((bullet, bulletIndex) =>
                            <li key={bulletIndex} className={'ml-7 mt-1'}>
                                { props.fsPrice
                                    ? bulletIndex === 0
                                        // monthly price
                                        ? <><Trans i18nKey={bullet} values={{ monthly: fsMonthly }} /></>
                                        : <Trans i18nKey={bullet} values={{
                                            annual: fsAnnual,
                                            monthly: fsAnnualInMonth
                                        }} />
                                    : <Trans i18nKey={bullet} />
                                }
                            </li>
                        )}
                    </ul> )}
                </>
            default:
                return props.faq.response;
        }
    }

    return(
        <div className="p-4 border-b border-gray-100" onClick={() => setOpened(!opened)}>
            <div className="flex justify-between place-items-center px-3">
                <p className={`text-sm ${opened ? 'text-secondary' : ''}`}>{props.faq.question}</p>
                <div className={`ml-3 carret${ opened ? '-open' : '-close'}`} />
            </div>
            <div className={!opened ? "hidden": "border-l border-l-fizzer-blue-50 ml-3 mt-5 pl-5 pr-1 text-xs"}>
                { setResponse(props.id) }
            </div>

        </div>
    )
}
