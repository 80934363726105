import {useTranslation} from "react-i18next";

export interface IQuestionAnswer { question: string, answer: string }

export const DetailQuestionAnswer = (props: { qa: IQuestionAnswer, index: number, product: string, credits: number }) => {

    //Init translation
    const { t } = useTranslation();

    //Get the product title
    const productTitle = t(`${props.product}.product-title`);

    return(
        <div className="text-center mt-7">
            <h1 className="pb-5 text-xl">{ t(props.qa.question, { 'product-title': productTitle, count: props.credits }) }</h1>
            <p className="text-sm">{ t(props.qa.answer, { 'credits': props.credits, 'product-title': productTitle, count: props.credits }) }</p>
        </div>
    )
}