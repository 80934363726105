//Languages
import i18n, {changeLanguage} from "i18next";
import { initReactI18next } from "react-i18next";

//Translations
import DE from './locales/de';
import EN from './locales/en';
import ES from './locales/es';
import FR from './locales/fr';
import IT from './locales/it';
import NL from './locales/nl';
import PT from './locales/pt';

//Styles
import './App.scss';

//Router
import {BrowserRouter, Route, Routes} from "react-router-dom";

//Pages
import {Postcard} from "./pages/products/postcard";
import {CardVideo} from "./pages/products/card-video";
import {CardSet} from "./pages/products/card-set";
import {Detail} from "./pages/products/detail/detail";
import {NotFound} from "./pages/not-found";
import {Magazine} from "./pages/products/magazine";
import {Greeting} from "./pages/products/greeting";
import {Magnetic} from "./pages/products/magnetic";
import {PhotoBook} from "./pages/products/photobook";
import {SquareMagnetic} from "./pages/products/square-magnetic";
import {ClubFizzerFaq} from "./pages/faq/club-fizzer-faq";

//Components
import {ProductPricesTab} from "./components/subscription-faq/product-prices-tab";
import {Popup} from "./components/popup";

//Contexts
import {PopupContext} from "./contexts/popup-context";
import {useEffect, useMemo, useState} from "react";

// services
import {getPrices} from './services/prices.service';
import {currencyByUrlParamOrBrowserLanguage} from './helpers/currencies';
import {getPricesFromLocalStorage, Prices, setPricesIntoLocalStorage} from './helpers/prices';
import {FamilySpace} from './pages/subscription/family-space';
import {FamilySpaceFaq} from './pages/faq/family-space-faq';
import {Gift} from "./pages/products/gift";

//Init i18n translation
i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {translation: DE},
            en: {translation: EN},
            es: {translation: ES},
            fr: {translation: FR},
            it: {translation: IT},
            nl: {translation: NL},
            pt: {translation: PT}
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    }).then(/* do nothing */);

//Supported langages
const supportedLangages = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt'];
const DEFAULT_LNG = 'en';

function App() {

    // state of price api called
    const [pricesLoaded, setPricesLoaded] = useState(false);

    //Get the current pathname
    const lang = document.location.pathname.split('/')[1];
    const product = document.location.pathname.split('/')[2];
    const currency = currencyByUrlParamOrBrowserLanguage();

    //State of popup
    const initialState = { display: false, shipping: false };
    const [{ display, shipping }, setPopup] = useState(initialState);

    //Callback to change the popup
    const popupValue = useMemo(() => {
        return {
            display: display,
            shipping: shipping,
            setDisplay: (display: boolean, shipping: boolean) => setPopup({display: display, shipping: shipping})
        }
    }, [display, shipping]);

    // hook for get price from api and update localStorage (re-rend app component on loaded)
    const prices: Prices | null = getPricesFromLocalStorage();
    useEffect(() => {
        if(!prices || prices.currency !== currency){ // if local stored currency is different from params
            getPrices(currency).then(prices => {
                setPricesIntoLocalStorage(prices);
                setPricesLoaded(true);
            });
        }
    }, [pricesLoaded]);

    //Check supported langages or set default 'en' lang
    supportedLangages.includes(lang) ? changeLanguage(lang) : changeLanguage(DEFAULT_LNG);

    const getProduct = (product?: string) => {
        switch (product){
            case 'card-set':
                return <CardSet/>
            case 'card-video':
                return <CardVideo/>
            case 'gazette':
            case 'magazine':
                return <Magazine/>
            case 'greeting':
                return <Greeting/>
            case 'magnetic':
                return <Magnetic/>
            case 'photobook':
                return <PhotoBook/>
            case 'postcard':
                return <Postcard/>;
            case 'square-magnetic':
                return <SquareMagnetic/>;
            case 'gift':
                return <Gift/>
            default:
                return <NotFound/>;
        }
    }

    return (
        <PopupContext.Provider value={popupValue}>
            <BrowserRouter>
                <Routes>
                    { /* lang route */ }
                    <Route path={i18n.language}>

                        <Route path=':product'>
                            { /* product */ }
                            <Route path='' element={ getProduct(product) } />

                            { /* product details */ }
                            <Route path='detail' element={<Detail/>} />

                            {/* not in product list */}
                            <Route path="*" element={<NotFound />} />
                        </Route>

                        { /* Carte blanche prices (for landing page) */ }
                        <Route path='faq-carte-blanche' element={<div className="p-5"><ProductPricesTab/></div>}/>

                        { /* Old FAQ Club Fizzer route */ }
                        <Route path='subscription-faq' element={<ClubFizzerFaq/>} />

                        { /* FAQs (Club Fizzer + Family Space) */ }
                        <Route path='faq'>
                            <Route path='club-fizzer' element={<ClubFizzerFaq/>} />
                            <Route path='family-space' element={<FamilySpaceFaq/>} />
                        </Route>

                        { /* Subscriptions route (Family Space) */ }
                        <Route path='subscriptions'>
                            <Route path='family-space' element={<FamilySpace/>} />
                        </Route>
                    </Route>

                    {/* all other routes -> redirect 404 */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Popup />
            </BrowserRouter>
        </PopupContext.Provider>
  );
}

export default App;
