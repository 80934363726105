import {InstructionBlock, IInstructionBlock} from "./instructionBlock";

interface InstructionsProps {
    title: string,
    instructions: IInstructionBlock[]
}

export const Instructions = (props: InstructionsProps) => {
    return(
        <div className="mt-6 mx-4">
            <h3 className="text-center">{props.title}</h3>

            <div className="flex flex-wrap justify-evenly">
                { props.instructions.map((instruction: IInstructionBlock, i: number) => {
                    return <InstructionBlock key={i} instruction={instruction} />
                }) }
            </div>
        </div>
    )
}