import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../assets/styles/swiper.scss';
import {getPath} from '../../helpers/path';

export const HeaderImage = (props: { imgCount: number }) => {

    const paths: string[] = [];
    const path = getPath();
    for(let i = 1; i <= props.imgCount; i++){
        const source = `assets/images/${path}/${path}-${i}.jpg`;
        paths.push(source);
    }

    return(
        <Swiper
            className="flex justify-center"
            modules={[Pagination]}
            direction={"horizontal"}
            pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }}>

            {
                /* for each image */
                paths.map((imgSrc, i) =>
                    <SwiperSlide key={i}>
                        <img className="min-w-header-img w-full" src={imgSrc} alt={`${path} img`} />
                    </SwiperSlide>)
            }

            <div className="swiper-pagination"></div>
        </Swiper>
    )
}
