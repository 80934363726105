import {getPath} from '../../helpers/path';

interface InstructionBlockProps {
    instruction: IInstructionBlock
}

export interface IInstructionBlock {
    title: string,
    subtitle: string,
    image: string
}

export const InstructionBlock = (props: InstructionBlockProps) => {

    const path = getPath();
    const filePath = `assets/images/${path}/${props.instruction.image}`;

    return(
        <div className="mt-6 w-1/2 px-3">
            <div className="flex flex-col items-center">
                <img className="instruction-img" src={filePath} alt={`${path} ${props.instruction.title} img`}/>
                <p className="pt-3.5 font-bold text-center">{props.instruction.title}</p>
                <p className="text-center">{props.instruction.subtitle}</p>
            </div>
        </div>
    )
}
